.sidebar .main-menu ul li.active:after {
  content: none !important;
}
.sidebar .main-menu ul li a {
  height: 90px !important;
  text-align: center;
  word-break: break-word; 
  
}
.sidebar [class^="iconsminds-"]:before, [class*=" iconsminds-"]:before {
  width: auto !important;
}

.sidebar .main-menu ul li.active {
  background-color:  #922c88;
}

.sidebar .main-menu ul li.active a {
  color: #fff !important;
}
.navbar .navbar-logo {
  position: relative !important;
  margin: auto 5px !important;
}

.navbar .navbar-logo .logo {
  width: 183px !important;
  background-position: center center !important;
  background-size: contain !important;
}
@media (max-width: 768px) {
  .navbar .navbar-logo .logo, .navbar .navbar-logo .logo-mobile {
    width: 100px !important;
  }
  .navbar .menu-button-mobile {
    margin-left: 25px !important;
  }

  .container.main-for-login {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}