.nav-link.active {
  color: #fff !important;
  background-color: #922c88 !important;
  border-radius: 50px !important;
}

.nav-link:hover{
  cursor: pointer;
}

.hover-on-top{
  cursor: pointer;
}

.hover-on-top-clear{
  padding-left: 0.75rem;
}

.api-details-form .card-header-tabs  .active.nav-link {
  background-color: #fff !important;
  color: #000 !important;
}