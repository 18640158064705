.auth-navbar {
  padding: 0 10px !important;
}
a.navbar-logo {
    color: #3a3a3a;
    transition: color .2s;
    outline: initial!important;
    width: 200px;
}
.navbar .navbar-right-menu .header-icons {
 display: none;
}

.navbar .navbar-right-menu .header-icons .child {
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: middle;
  cursor: pointer;
}

@media (max-width: 370px) {

  .navbar .navbar-right-menu .header-icons .child {
    display: inline-block;
    padding: 1rem 0.2rem 1rem 0.5rem;
    vertical-align: middle;
    cursor: pointer;
  }
}

.auth-header .footer-content {
  margin-left: 60px !important;
}

.div1 {
  background-color: transparent;
  margin-bottom: 30px;
}

.div2 {
  padding-top: 20px;
  padding-bottom: 59px;
  margin-bottom: 60px;
  background: white;
}

.div4 {
  flex-basis: 40%;
  min-height: 100px;
  max-height: 388px;
}

.rounded-for-login{
  background: white;
  border-radius:  1.25rem;
  /* padding: 77px; */

}
.rounded-for-login .login-container {
  padding: 10px 20px;
}

.error-message:before {
  content: "\200b";
}

.fp-tcolor{
  font-size: 13.05px;
  color: grey;
}

.register-tcolor{
  font-size: 13.05px;
  color:#922c88;
}
.main-for-login .login-banner {
  display: none;
}

.auth-carousel-div {
  border-radius: 1.25rem;
  height: 160px;
  margin-bottom: 30px;
  background: white;
  flex-basis: 100%;
  padding: 20px;
  width: 100%;
  display: none;
}

.carousel-image{
  height: 120px;
}

.auth-navbar .dropdown-menu-right .btn-empty {
  width: 30px;
}
.auth-navbar .navbar-right 
{
  display: inline-block;
  margin-left: 10px;
}

.auth-slider-div {
  margin-top: 35px !important;
}

.field-icon {
  float: right;
  margin-left: -25px;
  margin-top: -28px;
  position: relative;
  z-index: 2;
  padding-right: 10px;
  cursor: pointer;
}



@media (min-width: 768px) {
  
  .navbar .navbar-right-menu .header-icons {
    display: inline-block;
  }
  .main-for-login .auth-carousel-div, .main-for-login .login-banner  {
    display: block;
  }
  .auth-navbar .navbar-right  {
    display: none;
  }
  .btn-sm.login-btn {
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 0.05rem;
    padding: .5rem 1.25rem;
    font-size: 12px;
  }

}