.error-message {
    color: #dc3545 !important;
    font-size: 12px;
    margin-top: 1px;
}

.user-profile-form .title-label label span {
  top: 3px !important;
}

.field-icon-cp {
  float: right !important;
  margin-left: -25px !important;
  margin-top: -25px !important;
  position: initial !important;
  z-index: 2 !important;
  padding-right: 10px !important;
  cursor: pointer !important;
}