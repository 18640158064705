.width-for-card-details{
    width:40px;
}

.width-for-card-details-2{
    max-width:100px;
    height: 20px;
    margin-right:20px;
}

.bold {
    font-weight: bold;
  }


.instruction-heading {
    background-color: #922c88 !important;
    color: white !important;
    border-radius: 5px 5px 5px 5px;
    padding: 5px 10px;
    width: 230px; 
    margin-bottom: 0px !important;
    cursor: pointer;
  }

.small-image-width-height{
  object-fit: contain;
  width: 100%;
  height: 100%;
}


.input-disabled {
  color: black !important;
  /* border:#922c88 !important;
  outline: #922c88 !important; */
  background-color: white !important;
}

.table-for-pop-up{
  border:1px;
}

.div-border{
  border: 1px;
  border-color: #922c88;
  border-style: solid;
}

/* .horizontal-for-hr-tag {
  width:600px;
} */

/* table, th, td {
  border: 1px solid black;
} */


.online-card-details .input-group div {
  width: 20px;
  height: 40px;
  margin: 0 2px;
}

.online-card-details .input-disabled {
  width: 30px;
}

.purchase-in-button {
  display: block;
}
.purchase-out-button {
  display: none;
}

.online-card-details .accordion-content {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
}

.online-card-details  .glyph-icon {
  margin-top: 4px;
  font-size: 14px;
}

.pin-trash {
  display: none;
}


@media (max-width: 425px) {
  .purchase-in-button {
    display: none;
  }
  .purchase-out-button {
    display: block;
  }
}