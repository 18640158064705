.master-table-padding-left-10px{
    padding-right: 20px !important;
}

.width-30{
    width:40% !important;
}

.width-50{
    width:50% !important;
}