.width-for-small-card{
  max-width:12px;
  max-height:12px;
}

.height-of-menu{
  height:400px;
}

.Select-input {
  height: 300px;
}

.customer-manage-price .react-select__value-container {
  display : block;
}

.customer-manage-price .react-select__value-container  .react-select__multi-value {
  justify-content: space-between;

}
.customer-manage-price .react-select__value-container  .react-select__multi-value {
  justify-content: space-between;

}


.customer-manage-price .react-select__control  .react-select__control--menu-is-open .react-select__control--is-focused .react-select__control--is-selected   {
 
  background-color: rgb(20, 119, 212);

}