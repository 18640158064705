.heading{
  background-color: #922c88 !important;
  color: white !important;
  border-radius: 5px 5px 5px 0px;
  padding: 5px 10px 0px 10px ;
  width: 300px;
  margin-bottom: 0px !important;
}
.p-connected-overlay-enter-done {
  top: 265px !important;
}
.p-treeselect-label{
  white-space: normal;
  height: 100px !important;
  overflow: auto !important;
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 0.25rem;
  margin: 2px;
  background: #ffff;
  color: #495057;
  border-radius: 0px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 13px;
  font-weight: 500;
}

.p-treeselect-panel .p-treeselect-header {
  display: none !important;
}
.tag-item .search {
  border-bottom: none !important;
}

.infinite-scroll-component {
  height: 200px !important;
  overflow: auto;
}

.tag {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85% !important;
  overflow: hidden;
  padding: 3px !important;
  padding-left: 6px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #ffff !important;
}

.tag-remove {
  font-size: 15px !important;
  color: #000000 !important;
  font-weight: bold !important;
  padding: 1px 6px 1px 10px !important;
}

.tag-item {
  margin: 2px !important;
}

.p-treeselect-label {
  white-space: normal;
  height: 100px !important;
  overflow: auto !important;
}

.p-treeselect-panel .p-treeselect-header {
  display: none !important;
}

.p-treeselect {
  width: 100% !important;
}

.p-treeselect-items-wrapper {
  overflow: auto;
  max-height: 300px !important;
}

.p-connected-overlay-enter-done {
  top: 265px !important;
}

.p-dropdown-panel {
  top: 195px !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #495057;
  background: #fff !important;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #495057;
  background: #fff !important;
}

.p-dropdown {
  width: 100% !important;
  border-radius: 0px !important;
}

.p-dropdown .p-dropdown-trigger {
  width: 2.1rem !important;
}

.p-icon {
  width: 0.6rem !important;
  height: 1rem;
}

.p-inputtext {
  padding: 0.45rem 0.75rem !important;
  color: hsl(0, 0%, 50%) !important;
  font-size: 14px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1.25rem !important;
}

.circle-icon-user {
  padding: 7px 10px;
  border-radius: 50%;
  border: 1px solid #922c88;
  font-size: 16px;
  color: #922c88;
  vertical-align: middle;
  margin-left: 10px;
}

.table-responsive table thead th {
  text-align: center;
}
.quantity-cls {
  cursor: pointer;
  text-transform: capitalize;
  color: #007bff !important;
}

.menu-list-container {
  max-height: 250px;
  overflow-y: auto;
}

.menu-group {
  margin-bottom: 10px;
}

.group-label {
  font-weight: bold;
}

.menu-items {
  list-style-type: none;
  padding: 0;
}

.menu-items li {
  cursor: pointer;
  padding: 5px;
}

.menu-items li:hover {
  background-color: #f0f0f0;
}



.notification-modal i {
    font-size: 70px;
}

.notification-modal i.simple-icon-check, .success-text {
  color: #2c927a;
}

.notification-modal i.simple-icon-close {
  color: #922c2c;
}

.notification-modal i.simple-icon-exclamation {
  color: #ffc107;
}

.notification-modal  .duplicate-pin-details {
  height: 100px;
  overflow-y: auto;
}

.international-phone-input.react-tel-input .form-control {
    width: 100% !important;
}

.international-topup-operator {
  width: 50px;
  height: 50px;
}
.international-topup-operator img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.international-topup-mobile-input-card {
  height: 167px;
}

.logo-single {
  width: 100% !important;
  height: 100px !important;
}

.alphabet-division{
  padding: 7px;
  cursor:pointer;
  font-size:15px;
  font-weight: bold;
}

.highlight-alphabet-division{
  padding: 10px;
  cursor:pointer;
  /* color:#922c88; */
  background-color: #922c88 ;
  color: white;
  text-decoration: underline;
  font-weight: 900;
  font-size:18px;
}

.page-heading {
  background-color: #922c88 !important;
  color: white !important;
  border-radius: 5px 5px 5px 0px;
  padding: 5px 10px;
  width: 300px; 
  margin-bottom: 0px !important;
  cursor: pointer;
}
.page-heading1 {
  background-color: #922c88 !important;
  color: white !important;
  border-radius: 5px 5px 5px 0px;
  padding: 5px 10px;
  width: auto; 
  margin-bottom: 0px !important;
  cursor: pointer;
}
.separator-line-color {
  border-bottom: 3px solid #922c88 !important;
  margin-top: -1px;
} 

.pagintaion-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}

@media (max-width: 768px) {
.invoice-padding{
  padding-left: 15px;
  padding-right: 15px;
}
}

@media (max-width: 768px) {
  .circle-icon-user {
    font-size: 12px;
  }
  
  .page-heading {
   
    margin-top: 1rem !important;
    border-bottom-left-radius: 5px;
  }
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7e787e;
}
.react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll  {
  margin: 0 20px !important;
}

.react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
  top: 3.5px !important;
}

.react-table-pagination i {
  cursor: pointer;
  font-size: 16px;
}

.react-table-pagination i[disabled] {
  cursor: initial;
  opacity: 0.5;
}

.cursor-pointer {
  cursor: pointer;
}

.admin-container .page-heading {
  width: 400px;
}

.td-link {
  color: #007bff !important;
}

.transform-rotate {
  transform: rotateZ(180deg);
}

.carousel-container {
  margin-right: 10% !important;
}

.account-holder-info {
  font-size: 12px;
  font-weight: bold !important;
  /* color: #922c88 ; */
}

.navbar .navbar-left {
  flex-basis: 0 !important;
}

.navbar {
  flex-wrap: nowrap !important;
}

.customer-nav .navbar-right-menu {
 width: fit-content !important;
}

.navbar .marquee-container .overlay {
  height: auto !important;
}

.marquee-card-image {
  width:75px;
  height:50px;
}

.url-link {
  cursor: pointer;
  text-transform: uppercase;
  color: #007bff !important;
}

.table-card-img {
  height: 50px;
  width: 100px;
}
@media screen and (max-width: 450px) {
  .workgrplbl {
      width: 150px !important;
  }
}

@media (max-width:991px) {
  .carousel-container {
    margin-right: 0 !important;
  }
}

@media (max-width:850px) {
  .alphabet-division{
    padding: 3px;
    font-size:8px;
    
  }
  
  .highlight-alphabet-division{
    padding: 5px;
    font-size:10px;
  }
 
}
@media (max-width:470px) {
  .alphabet-division{
    padding: 3px; 
    font-size:6px;
    
  }
  
  .highlight-alphabet-division{
    padding: 3px;
    
    font-size:8px;
  }
 
}

@media (max-width:576px) {
  .separator-line-color {
    display: none;
  }
  .admin-container .page-heading, .page-heading {
    margin-bottom: 1.5rem !important;
    width: 100%; 
  }
}

@media (max-width:768px) {
  .international-topup-mobile-input-card {
    height: 210px;
  }
}

.workgrpmargin{
  margin-left: 2.5rem;
}

@media (max-width: 380px) {
  .workgrpmargin {
    margin-left: 0px; /* Change margin-left to 0 for screens below 380px */
  }
}