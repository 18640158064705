/* trip card css */

.search-results-component .LocationsHorizontal__duration___rJ6rs:before {
    background-color: #e1e1e1;
    background-color: #e1e1e1;
    content: "";
    height: 1px;
    left: 0;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 50%;
}


.checkout-session-summary{
    position:fixed;
}


.flix-bus-containing-in-same-card{
    margin-top: -23px;
}


.search-result-page-spacing{
    margin-bottom:20px;
}


.flix-bus-h2 {
    width: 100%; 
    text-align: left; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 }  
 
 .flix-bus-hr{
    width: 100%; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px;    
 }
 
 .flix-bus-h2 span { 
     background:#fff; 
     padding:0 10px; 
 } 



/* tabs */

.tab-layout{
    text-align: center

}


.rcorners1 {
    border-radius: 25px;
    background: #922c88;
    padding: 20px;
    width: 200px;
    height: 150px;
  }


.custom-close-button-for-flixbus{
    display:block;
    text-align: center;
    margin-top: -18px;
    margin-bottom: 14px;

}


.icon-size-for-flixbus{
    font-size: 16px;
}

/* purple shade*/
.image1 { 
    filter: invert(21%) sepia(36%) saturate(4274%) hue-rotate(284deg) brightness(92%) contrast(87%);
}

/* white shade */

.flix-bus-button .image2   {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(16deg) brightness(103%) contrast(104%);
}

/* .flix-bus-button img {
    filter: invert(21%) sepia(36%) saturate(4274%) hue-rotate(284deg) brightness(92%) contrast(87%);
} */

.flix-bus-button {
    border-radius: 50% !important;
    padding: 5px 10px !important;
}

.stack-top{
    z-index: 9;
    margin: 30px; 
}

/* especially for from to and transfer */

.bar {
    list-style: none;
  }
  .bar >li {
    position: relative;
  }
  .bar>li:before {
    content: '\25CF';
    margin-right: 10px;
    font-size: 20px;
  }
  .bar>li:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    border-left: 2px solid black;
    margin-left: 5px;
    height: 100%;
  }
  .bar >li:first-of-type:after {
    top: 50%;
  }
  .bar >li:last-of-type:after {
    top: -50%;
  }

.trip-details-icons{
    height:15px;
    width:15px;
}

.box{
    width: 100%;
    height: 100%;            
}

.flixbus-swap-button{
    height:15px;
    width:15px;
}

.flixbus-transfer-icon{
    height:20px;
    width:20px;
}

.vertical-line{
    display: inline-block;
    -webkit-align-self: stretch;
    align-self: stretch;
    width: 3px;
    min-height: 1em;
    background-color: currentColor;
    opacity: .25;
    text-align: center;
    align-items: center;
}

.flixbus-tab-heading {
    background-color: white !important;
    color: black !important;
    border-radius: 5px 5px 5px 0px !important;
    padding: 10px 20px 10px 20px !important;
    margin-bottom: 0px !important;
}

.flixbus-tab-heading-active{
    background-color:#922c88 !important;
    color:white !important ;
    border-bottom: 10px #922c88;
    border-radius: 5px 5px 5px 0px !important;
    padding: 10px 20px 10px 20px !important;
    margin-bottom: 0px !important;
}

  
.flix-bus-trip-card .card-body .card-body {
    padding-top: 0 !important;
}
   
  
  .flixbus-Overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
  }
  
  .flixbus-Overlay.Show {
    display: block;
  }
  
  .flixbus-Modal {
    position: fixed;
    bottom: -150vh;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    padding: 0 12px 12px;
    transition: all 0.3s ease-out;
    z-index: 10;
    height: auto;
  }
  
  .flixbus-Modal.Show {
    bottom: 0;
  }
  
  .flixbus-Close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: transparent;
    border: 0;
    font-size: 18px;
  }
  
.flix-bus-station-list [class$="singleValue"], .flix-bus-station-list .react-select__input {
    margin-left: 25px;
}

.flix-bus-station-list [class$="placeholder"] {
    margin-left: 30px;
}

.flix-bus-station-list.from-list  .react-select__value-container {
    background-image: url('../assets/logos/icons8-get-on-bus-48.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}
.flix-bus-station-list.to-list .react-select__value-container {
    background-image: url('../assets/logos/icons8-get-off-bus-48.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.passenger-entry-info {
    display: block;
    opacity: 0.7;
    font-size: 10px;
}   

.tab-layout .rts___nav___btn {
    border-radius: 0;
} 
.tab-layout .rts___nav___btn {
    font-size: 20px;
}
.tab-layout .rts___nav___btn:hover {
    background-color:#fff !important;
}

.tab-layout .rts___nav___btn___container {
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}


.trip-card .trip-card-header {
  display: flex;
  flex-direction: row;
}

.trip-card .trip-card-header .trip-time {
    font-size: 16px;
    font-weight: bold;
}
.trip-card .trip-card-header .trip-time .station-name {
    display: block;
    font-size: 12px;
}

.trip-card .trip-card-header .trip-hour {
    font-weight: 400;
    opacity: 0.5;
    font-size: 14px;
}
.trip-card .trip-card-header .trip-hour::before, .trip-card .trip-card-header .trip-hour::after {
    background-color: currentColor;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 35%;
    opacity: 0.5;
}
.trip-card .trip-card-header .trip-hour::before {
    right: 0.5em;
    margin-left: -50%;
}

.trip-card .trip-card-header .trip-hour::after {
    left: 0.5em;
    margin-right: -50%;
}
.trip-card .trip-header-row .trip-price, .trip-card .trip-footer-row .trip-price {
    color:#922c88 !important ;
    font-weight: bold;
    font-size: 18px;
    text-align: right;
}
.trip-card .card-body {
    padding: 10px !important;
    min-height: 138px;
}

.trip-footer-row .transfer-details {
    border: 1px #c8c8c8 solid !important;
    border-radius: 30px;
    cursor: pointer;
}

.trip-footer-row .transfer-details:hover, .trip-footer-row .transfer-details:hover span {
    background-color:#922c88 !important ;
    color:#fff !important;
}

.trip-footer-row .transfer-details .iconsminds-bus-2 {
    font-size: 18px;
}
.trip-footer-row .transfer-details .simple-icon-arrow-right {
    font-size: 10px;
}

.trip-footer-row .transfer-details span {
    border-left: 1px #c8c8c8 solid !important;
}
.trip-footer-row  .bus-aminities {
    font-size: 16px;
    cursor: pointer;
}
.trip-type  .bus-aminities, .trip-type .bus-aminities i {
    font-size: 12px;
}

.trip-type  .flixbus-transfer {
    margin-left: 6em;
}

.trip-not-found i {
    /* font-size: 16px; */
    color: crimson;
}

.trip-type p.vertical-right {
    border-left: 3px solid #c8c8c8;
    height: 70px;
  }
  .trip-type .small-circle {
    content: "";
    height: 2px;
    width: 4px;
    border: 1px solid  #c8c8c8;
    border-radius: 50%;
    font-size: 16px;
  }
  .trip-type .small-circle.filled {
    background-color:  #c8c8c8;
  }


.trip-card .trip-time-skelton {
    width: 80%;
    height: 20px;
}
.trip-card .trip-station-skelton {
    width: 100%;
    height: 20px;
}
.trip-card .trip-transfer-skelton {
    width: 150px;
    height: 20px;
    margin-right: 5px;
}
.trip-card .trip-amenities-skelton {
    width: 80px;
    height: 20px;
}

.trip-calender-skeleton {
    --base-color: #ffffffa9 !important;
    width: 100%;
    height: 38px;
}

.selected-checked-cart {
   max-height: 18px;
}

@media (max-width: 992px) {
    .trip-card .trip-card-header .trip-hour::before, .trip-card .trip-card-header .trip-hour::after {
        width: 7%;
    }
        
 }

@media (max-width: 425px) {
    .flix-bus-trip-card  .card {
        width: auto;
    }
    .trip-type .bus-aminities, .trip-type .bus-aminities i {
        font-size: 10px;
    }
}

@media (max-width:720px){
.flix-bus-pop-down{
    width:20px
}
}



