.margin-left-forradio-button{
    margin-left:19px !important;
}

.margin-bottom-for-button{
    margin-bottom:10px;
}

.label-font-weight{
    font-weight:600;
}

@media (max-width: 768px) {
    .config-go-btn{
        float: right;
    }
}