.admin-card-details .instruction {
  cursor: pointer;
  text-transform: uppercase;
  color: #007bff !important;
}

.admin-card-details .active {
  cursor: pointer;
  text-transform: uppercase;
  color: green !important;
}

.admin-card-details .deactive {
  cursor: pointer;
  text-transform: uppercase;
  color: red !important;
}

.admin-card-details .actions i, .admin-card-denomination .actions i {
  font-size: 16px;
  cursor: pointer;
}

.login-instruction-modal .modal-header {
  display: block !important;
  background-color: #922c88;
  color: white;
}

.login-instruction-modal .modal-header .close {
  padding: 0rem 1rem !important;
  color: white;
}

.login-instruction-modal .modal-content  {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 15px !important;
}

.modal-dialog {
  box-shadow: none !important;
}

.login-instruction-modal .instruction {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
}

.login-instruction-modal .login-message {
  white-space: pre-wrap;
  font-size: 17px !important;
  line-height: 1.5;
}

.login-instruction-modal .card-img {
margin: 0 auto;
  width: 300px;
  height: 150px;
}

.login-instruction-modal .card-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}




.card-instruction-modal .instruction {
  white-space: pre-wrap;
  font-size: 13px;
  line-height: 1.5;
}

.card-instruction-modal .login-message {
  white-space: pre-wrap;
  font-size: 17px !important;
  line-height: 1.5;
}

.card-instruction-modal .card-img {
margin: 0 auto;
  width: 300px;
  height: 150px;
}

.card-instruction-modal .card-img img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.card-details-form .card-group {
  margin-bottom: 40px !important;
}

.card-details-form .card-group-label {
  left: -2px !important;
  top: -9px !important;
  width: 200px !important;
}
.card-details-form .custom-radio {
  margin-top: 30px !important;
}

.card-details-form .img-file {
  margin-top: 20px !important;
}

.card-details-form .img-file label span {
  top: -18px !important;
  left: -3px !important;
}

.card-details-form .discount-label label > span {
  top: 12px;
}   

.admin-card-denomination .card-img-container {
  height: 50px;
  width: 100px;
}

.admin-card-denomination  .showonly {
  margin-bottom: 20px;
}

.admin-card-denomination .card-img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.badge-primary.green-back-label, .card-details-form .custom-switch   {
  background-color:   #2c927a !important;
}

.badge-primary.red-back-label, .card-details-form  .custom-switch.rc-switch-checked.custom-switch-primary {
  background-color:#922c2c !important;
}



/* .card-details-form .custom-switch  {
  background:  #28a745 !important;
} */

.sales-report .table .bottom-border {
  border-bottom: 0.7px solid black !important;
}

.sl-instruction {
  font-weight: bold;
}